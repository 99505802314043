@charset "utf-8";
@import url('https://fonts.googleapis.com/css?family=Noto+Serif+JP');

$desktop: 640px;
$widescreen: $desktop;
$fullhd: $desktop;

$primary: hsl(0, 0%, 4%);
$red: hsl(358, 84, 60);
$progress-indeterminate-duration: 3s;
$family-primary: 'Noto Serif JP',
serif;

@import "~bulma/bulma";

.hero.is-fullheight {
  min-height: calc(100vh - var(--vh-offset, 0px));
}

.title a {
  color: $black;
}

.footer {
  hr {
    margin-top: 0;
  }

  background-color: $white;
  padding-top: 0;
  padding-bottom: 3rem;
}

.logo {
  background-image: url('../assets/images/logo.svg');
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  height: 64px;
}

.shogi {
  .user-info {
    text-align: left;

    span {
      vertical-align: middle;

      &.tag {
        font-size: 0.5rem;
      }
    }

    .turn {
      display: inline-block;

      &.active {
        background-color: $red;
        border-radius: 3px;
      }
    }

    .clock {
      padding: 0;
      font-family: "Courier New", Consolas, monospace;

      &.alert {
        color: $red;
      }
    }
  }

  .col {
    padding: 0;
    margin: 0;
    position: relative;
    text-align: center;

    cursor: pointer;

    .piece {
      position: absolute;

      top: 0px;
      right: 0px;
      bottom: 0px;
      left: 0px;

      background-image: url('../assets/images/piece.svg');
      background-position: 50% 50%;
      background-repeat: no-repeat;
    }

    .label {
      display: flex;
      flex-direction: column;
      justify-content: center;

      position: absolute;

      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;

      span {
        height: 1em;
        line-height: 1.25em;
      }

      &.promoted {
        color: $red;
      }

      &.promoted2 {
        transform: scaleY(0.5);

        span {
          height: 1.2em;
        }
      }
    }

    &.away {

      .piece,
      .label {
        transform: rotate(180deg);
      }

      .label {
        &.promoted2 {
          transform: rotate(180deg) scaleY(0.5);
        }
      }
    }

    &.selected {
      background-color: $grey-lighter;
    }

    &.resign {
      vertical-align: middle;
    }
  }

  .stand {
    border: 1px solid $grey;
    margin: 0 auto;

    .col {
      border-collapse: separate;

      .num {
        background: $black;
        border-radius: 50%;
        color: $white;
        position: absolute;

        bottom: -0.25em;
        right: -0.25em;
        height: 1.5em;
        width: 1.5em;

        display: flex;
        justify-content: center;
        align-items: center;
      }

      &.away .num {
        transform: rotate(180deg);

        top: -0.25em;
        left: -0.25em;
      }
    }
  }

  .board {
    .col {
      border: 1px solid $grey;

      .dot {
        background: $black;
        border-radius: 50%;
        position: absolute;
        top: -3px;
        left: -3px;
        height: 5px;
        width: 5px;
      }

      .move {
        display: inline-block;
        background: $red;

        position: absolute;
        transform: translateY(-50%) translateX(-50%);

        top: 50%;
        left: 50%;

        z-index: 1;

        &.move-selected {
          background: $red;
        }
      }

      &.moved {
        background-color: $grey;
      }

      &.track {
        background-color: $grey-lighter;
      }
    }
  }
}

@mixin shogi ($unit) {
  $width : calc(#{$unit} / 10);
  $height : calc(#{$unit} / 9);
  $padding : calc(#{$unit} / 40);

  .shogi {
    .user-info {
      width: calc(#{$width} * 9);
      margin: calc(#{$padding} * 0.5) auto;

      span {
        font-size: calc(#{$width} * 0.5);
      }

      .turn {
        height: calc(#{$height} * 0.5);
        width: calc(#{$padding} * 1);
      }

      .clock {
        margin: 0 calc(#{$padding} / 2) 0 calc(#{$padding} / 4);
      }
    }

    .col {
      width: $width;
      height: $height;

      .piece {
        background-size: $width calc(#{$height} * 0.85);
      }

      .label {
        font-size: calc(#{$width} * 0.5);
      }
    }

    .col[colspan="2"] {
      width: calc(#{$width} * 2);
    }

    .stand .col .num {
      font-size: calc(#{$width} * 0.35);
    }

    .board {
      margin: $padding auto;

      .col .move {
        border-radius: calc(#{$width} * 0.25);
        width: calc(#{$width} * 0.25);
        height: calc(#{$width} * 0.25);
      }
    }
  }

  @content;
}


@include shogi(100vw);

@media screen and (min-width: $desktop) {

  .navbar.is-black .navbar-start>a.navbar-item.is-active,
  .navbar.is-black .navbar-end>a.navbar-item.is-active {
    background-color: $white;
    color: $black;
  }

  .navbar.is-black .navbar-dropdown a.navbar-item.is-active {
    background-color: $white;
    color: $blue;
  }

  @include shogi($desktop) {

    // なぜかはみ出るので追加
    .shogi .user-info {
      width: auto;
    }
  }
}